<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row ">

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="small-box bg-gradient-success">
                    <div class="inner">
                      <!--<template v-if="Object.keys(totalRevenueWhole).length > 0">-->
                      <!--  <h4>{{ totalRevenueWhole.totalRevenue | isNotNull | formatAmountFilter }}</h4>-->
                      <!--  <p>Revenue</p>-->
                      <!--</template>-->
                      <!--<template v-else>-->
                      <!--  <h4>0</h4>-->
                      <!--  <p>Revenue</p>-->
                      <!--</template>-->
                      <h4>WIP</h4>
                      <p>Revenue</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-chart-bar"></i>
                    </div>
                    <a href="#" class="small-box-footer">
                        Total Running Revenue as of Today
                    </a>
                    </div>
                </div>
                <!-- /.col -->

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="small-box bg-gradient-warning">
                    <div class="inner">
<!--                        <h4>{{ formatAmount(summary.totalWins) }}</h4>-->
                        <h4>WIP</h4>
                        <p>Wins</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-dollar-sign"></i>
                    </div>
                    <a href="#" class="small-box-footer">
                        Total Running Amount of Wins/Payout
                    </a>
                    </div>
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="small-box bg-gradient-danger">
                    <div class="inner">
<!--                        <h4>{{ formatAmount(summary.totalBets) }}</h4>-->
                        <h4>WIP</h4>
                        <p>Bets</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-dollar-sign"></i>
                    </div>
                    <a href="#" class="small-box-footer">
                        Total Running Amount of Bets/Wager
                    </a>
                    </div>
                </div>
                <!-- /.col -->

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="small-box bg-gradient-info">
                    <div class="inner">
<!--                        <h4>{{ summary.totalMembers }}</h4>-->
                        <h4>WIP</h4>
                        <p>Members</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <a href="#" class="small-box-footer">
                        Total Number of Players
                    </a>
                    </div>
                </div>
            <!-- /.col -->
            
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-12">

                  <div class="card">
                    <div class="card-header bg-gradient-info">
                      <div class="row">
                        <div class="col-md-10">
                          <h3 class="card-title">Total Revenue per Category</h3>
                        </div>
<!--                        <div class="col-md-2 float-right">-->
<!--                          <div class="timeRangeDropdown">-->
<!--                            <template>-->
<!--                              <v-select label="timeRangeDesc" :options="timeRangeOptions" v-model="selectedTimeRange" @input="totalRevenuePerCategory" :placeholder="timeRangePlaceholder"></v-select>-->
<!--                            </template>-->
<!--                          </div>-->
<!--                        </div>-->
                      </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body p-0">
                      <div class="table-responsive">
                        <table class="table table-hover">
                          <thead class="blue">
                          <tr>
                            <th>Category</th>
                            <th>Total Revenue</th>
                          </tr>
                          </thead>
<!--                          <tbody>-->
<!--                          <template v-if="total_revenue.length > 0">-->
<!--                            <tr v-for="rev in total_revenue" :key="rev.category">-->
<!--                              <td>{{ rev.category | isNotNull }}</td>-->
<!--                              <td>{{ rev.revenue | isNotNull | formatAmountFilter }}</td>-->
<!--                            </tr>-->
<!--                          </template>-->
<!--                          <template v-else>-->
<!--                            <tr>-->
<!--                              <td colspan="2" style="text-align: center">Loading...</td>-->
<!--                            </tr>-->
<!--                          </template>-->
<!--                          </tbody>-->
                          <tbody>
                            <tr>
                              <td colspan="2" style="text-align: center">WIP. Not available at the moment.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                    <!-- /.card-body -->
                  </div>
                  <!-- /.card total revenue per category -->

                    <!-- top 10 games played -->
                    <div class="card">
                        <div class="card-header bg-gradient-info">
                          <div class="col-md-10">
                            <h3 class="card-title">Top 10 Games Played</h3>
                          </div>
<!--                          <div class="col-md-2 float-right">-->
<!--                            <div class="timeRangeDropdown">-->
<!--                              <template>-->
<!--                                <v-select label="name" :options="gamesTimeRangeOptions" v-model="selectedGamesTimeRange" @input="topGamesPlayed" :placeholder="gamesTimeRangePlaceholder"></v-select>-->
<!--                              </template>-->
<!--                            </div>-->
<!--                          </div>-->
                        </div>

                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead class="blue">
                                    <tr>
                                    <th>#</th>
                                    <th>Game Name</th>
                                    <th>Count</th>
                                    </tr>
                                    </thead>
<!--                                    <tbody>-->
<!--                                        <template v-if="games.length > 0">-->
<!--                                            <tr v-for="(game, index) in games" :key="game.gameId">-->
<!--                                              <td>{{ index + 1 }}</td>-->
<!--                                              <td>{{ game.name | isNotNull }}</td>-->
<!--                                              <td>{{ game.game_count | isNotNull }}</td>-->
<!--                                            </tr>-->
<!--                                        </template>-->
<!--                                        <template v-else>-->
<!--                                            <tr>-->
<!--                                            <td colspan="3" style="text-align: center">Loading...</td>-->
<!--                                            </tr>-->
<!--                                        </template>-->
<!--                                    </tbody>-->
                                  <tbody>
                                    <tr>
                                      <td colspan="3" style="text-align: center">WIP. Not available at the moment.</td>
                                    </tr>
                                  </tbody>
                                </table>
                            </div>
                        </div>
                         <!-- /.card-body -->
                    </div>
                    <!-- /.card top 10 games played -->

                    <!-- top 10 players -->
                    <div class="card">
                        <div class="card-header  bg-gradient-info">
                            <h3 class="card-title">Top 10 Players</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead class="blue">
                                      <tr>
                                        <th>#</th>
                                        <th>Player ID</th>
                                        <th>Transaction Count</th>
                                      </tr>
                                    </thead>
<!--                                    <tbody>-->
<!--                                        <template v-if="players.length > 0">    -->
<!--                                            <tr v-for="(player, index) in players" :key="player.userId">-->
<!--                                              <td>{{ index + 1 }}</td>-->
<!--                                              <td>{{ player.userId | isNotNull }}</td>-->
<!--                                              <td>{{ player.player_count | isNotNull }}</td>-->
<!--                                            </tr>-->
<!--                                        </template>-->
<!--                                        <template v-else>-->
<!--                                            <tr>-->
<!--                                            <td colspan="3" style="text-align: center">Loading...</td>-->
<!--                                            </tr>-->
<!--                                        </template>-->
<!--                                    </tbody>-->
                                  <tbody>
                                  <tr>
                                    <td colspan="3" style="text-align: center">WIP. Not available at the moment.</td>
                                  </tr>
                                  </tbody>
                                </table>
                            </div>
                        </div>
                         <!-- /.card-body -->
                    </div>
                    <!-- /.card top 10 players -->

                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>

<script>

    const axiosCronClient = require('axios');

    export default {
        components: {},
        data () {
            return {
                summary: {},
                games: {},                
                players: {},
                total_revenue: {},

              // TOTAL REVENUE PER CATEGORY time range
                timeRangeOptions: [
                  { timeRangeDesc: 'Yesterday', value: 1 },
                  { timeRangeDesc: '1 week', value: 7 },
                  { timeRangeDesc: '1 month', value: 30 },
                  { timeRangeDesc: '90 days', value: 90 },
                  { timeRangeDesc: '1 year', value: 365 },
                ],
                timeRangePlaceholder: 'Select Time Range',
                selectedTimeRange: [],

              // TOP 10 GAMES time range
                gamesTimeRangeOptions: [
                  { name: 'Yesterday', value: 1 },
                  { name: '1 week', value: 7 },
                  { name: '1 month', value: 30 },
                  { name: '90 days', value: 90 },
                  { name: '1 year', value: 365 },
                ],
                gamesTimeRangePlaceholder: 'Select Time Range',
                selectedGamesTimeRange: [],
                totalRevenueWhole: {},
            }
        },
        methods: {
            async loadDashboardSummary(){
                let res = await axiosCronClient.get(process.env.MIX_CRON_API_URL + '/total-revenue?frequency=whole', {
                  headers: {
                    'Authorization': `Bearer ${process.env.MIX_CRON_API_KEY}`
                  }
                })
                this.totalRevenueWhole = res.data
            },

            topGamesPlayed(){
                axios.get("/api/v1/transactions/top-games", {
                  params: {
                    time_range: this.selectedGamesTimeRange !== null ? this.selectedGamesTimeRange.value : 1
                  }
                })
                .then(({ data }) => (                                                  
                    this.games = data
                ));                
            },

            topPlayers(){
                axios.get("/api/v1/transactions/top-players")
                .then(({ data }) => (                                     
                    this.players = data
                ));                
            },

            totalRevenuePerCategory(){
              axios.get('/api/v1/transactions/revenue-per-category', {
                params: {
                  time_range: this.selectedTimeRange !== null ? this.selectedTimeRange.value : 1
                }
              })
                  .then(({ data }) => {
                    this.total_revenue = data
                  });
            },

            formatAmount(value) {
                let val = (Number(value)).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        },


        created() {            
            //this.loadDashboardSummary();
            // this.totalRevenuePerCategory();
            // this.topGamesPlayed();
            // this.topPlayers();
        },

        filters: {
            isNotNull(value) {
                return !!value ? value : '';
            },
            roundOfToTwo(value) {
              return value.toFixed(2);
            },
            formatAmountFilter(value) {
              let val = (Number(value)).toFixed(2)
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        }
    }    
</script>
<style>
    h4 {
        font-weight: bold;
        font-size: 30px;
    }
    .timeRangeDropdown {
      color: black;
    }
</style>
