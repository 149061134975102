<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">

        <div class="col-12">

          <flash message=""></flash>

          <div class="card">

            <div class="card-header">
              <h3 class="card-title">List of Payments Report</h3>
            </div>


            <div class="card-header">              
              <div class="card-tools">
                <div class="input-group">

                  <div class="typeDropdown">
                    <v-select label="transTypeName" :options="transTypeOptions" v-model="selectedTransType" @input="loadTransactions" :placeholder="transTypePlaceholder"></v-select>
                  </div>

                  <span class="input-space"></span>
                                            
                  <div class="reportDates">
                    <date-range-picker
                        ref="picker"
                        :opens="opens"
                        :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd HH:mm:ss' }"
                        :minDate="minDate" :maxDate="maxDate"
                        :timePicker="timePicker"
                        :timePickerSeconds="timePickerSeconds"
                        :timePickerIncrement="timePickerIncrement"
                        :showDropdowns="showDropdowns"
                        v-model="dateRange"
                        @update="loadTransactions"
                    >
                      <template v-slot:input="picker" style="min-width: 350px;">
                        {{ picker.startDate | formatDateRangeInput }} - {{ picker.endDate | formatDateRangeInput }}
                      </template>
                    </date-range-picker>
                  </div>
                  
                  <span class="input-space"></span>

                  <div class="downloadBtn">
                    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Download
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a
                          class="dropdown-item"
                          href="#"
                          @click="download('csv')"
                      >As CSV</a>
                      <a
                          class="dropdown-item"
                          href="#"
                          @click="download('xlsx')"
                      >As Excel</a>
                    </div>
                  </div> 
              
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover" aria-label="Deposits and Withdrawals by date range selected">
                <thead>
                <tr>
                  <th>Payment ID</th>
                  <th>User ID</th>
                  <th>Created Date</th>                                    
                  <th>Type</th>                  
                  <th>Currency</th>
                  <th>Amount</th>
                  <th>Status</th>    
                  <th>Provider</th>                  
                  <th>Method</th>      
                  <th>Agent</th>    
                  <th>Cashier</th>
                  <th>ExternalPaymentID</th>                                                    
                </tr>
                </thead>
                <tbody>
                <template v-if="transactions.data.length > 0">
                  <tr v-for="transaction in transactions.data" :key="transaction.id">                                      
                    <td>{{ transaction.paymentId | isNotNull }}</td>
                    <td>{{ transaction.userId | isNotNull }}</td>
                    <td>{{ transaction.settlement_date | formatDate | isNotNull }}</td>                    
                    <td>{{ transaction.type | isNotNull}}</td>
                    <td>{{ transaction.currency | isNotNull }}</td>                                        
                    <td>{{ transaction.amount | isNotNull }}</td>                                        
                    <td>{{ transaction.status | isNotNull }}</td>                      
                    <td>{{ transaction.provider | isNotNull }}</td>
                    <td>{{ transaction.method | isNotNull }}</td>
                    <td>{{ transaction.agent | isNotNull }}</td>
                    <td>{{ transaction.cashierName | isNotNull }}</td>
                    <td>{{ transaction.externalPaymentId | isNotNull }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="12" style="text-align: center">No data found within those dates</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <pagination :data="transactions" :limit="2" @pagination-change-page="getResults"></pagination>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import moment from "moment";

export default {
  components: {},
  data () {
    let startDate = moment().subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss');
    let endDate = moment().format('YYYY-MM-DD HH:mm:ss');

    return {
      transactions: {
        data: {}
      },

      //date-range-picker params
      opens: 'center',
      dateRange: {
        startDate,
        endDate
      },
      minDate: moment('2000-01-01 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      timePicker: true,
      timePickerSeconds: true,
      timePickerIncrement: 1,
      showDropdowns: true,
      reportType: 'list-payment',

      //type options
      transTypeOptions: [
        {transTypeName: 'Deposit', value: 'Deposit'},
        {transTypeName: 'Withdraw', value: 'Withdraw'},
        {transTypeName: 'Bonus', value: 'Bonus'},
        // {transTypeName: 'Correction', value: 'Correction'},
        // {transTypeName: 'Withdrawal Cancel', value: 'WithdrawalCancel'}
      ],
      transTypePlaceholder: 'Filter by Type',
      selectedTransType: [],
      
    }
  },
  methods: {
    loadTransactions(){
      this.$Progress.start();
      
      axios.get("/api/v1/transactions", {
        params: {
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),          
          reportType: this.reportType,
          transType: this.selectedTransType ? this.selectedTransType.value : ''
        }
      }).then(({ data }) => (this.transactions = data))
          .catch(error => console.error(error));

      this.$Progress.finish();
    },
    getResults(page = 1) {
      this.$Progress.start();

      axios.get("/api/v1/transactions", {
        params: {
          page: page,
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
          reportType: this.reportType,
          transType: this.selectedTransType ? this.selectedTransType.value : ''
        }
      }).then(({ data }) => (this.transactions = data))
          .catch(error => console.error(error));

      this.$Progress.finish();
    },

    download(type) {
      this.$Progress.start();

      flash('Downloading now... Please wait...');

      const fileDownload = require('js-file-download');      

      const filename = 'Transactions_List_of_Payments_' +
          moment(this.dateRange.startDate).format('YYYY_MM_DD_HH_mm_ss') + '_to_' +
          moment(this.dateRange.endDate).format('YYYY_MM_DD_HH_mm_ss') +
          "." + type;

      axios.get('/api/v1/export/list-payments', {
        params: {
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
          type: type,
          reportType: this.reportType,
          transType: this.selectedTransType ? this.selectedTransType.value : '',
        },
        responseType: 'blob'
      }).then(response => {
        fileDownload(response.data, filename);
      });

      setTimeout(
          this.$Progress.finish(),
          10000
      );
    }
  },
  created() {
    this.loadTransactions();    
  },
  filters: {
    isNotNull(value) {
      return (value !== null) ? value : '';
    },
    formatDateRangeInput(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    }
  }
}
</script>
<style>
  .input-space {
    padding-left: 35px;
  }
  .card-tools {
    text-align: center; 
    width: 100%;
  }
</style>