export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/reports/transactions', component: require('./components/reports/TransactionsReport.vue').default },
    { path: '/reports/list-payments', component: require('./components/reports/ListOfPaymentsReport.vue').default },
    { path: '/reports/revenue-category', component: require('./components/reports/TotalRevenuePerCategoryReport.vue').default },
    { path: '/settings/games', component: require('./components/settings/Game.vue').default },
    { path: '/settings/whitelistip', component: require('./components/settings/WhitelistIP.vue').default },
    { path: '/maintenance/cache', component: require('./components/maintenance/CacheMaintenance.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
