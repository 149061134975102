<template>
  <section class="content">
    <div class="container-fluid">

      <div class="row">

        <div class="col-12">

          <flash message="" flashType=""></flash>

          <div class="card">
            <div class="card-header">
                <h3 class="card-title">Transactions by Date Range and Games Report</h3>
            </div>
            
            <div class="card-header">
              <div class="card-tools">
                <div class="input-group">
                                                                                    
                  <div class="transDropdown">
                    <template>
                      <v-select label="categoryName" :options="categoryOptions" v-model="selectedCategory" @input="loadTransactions" :placeholder="categoryPlaceholder"></v-select>
                    </template>
                  </div>

                  <span class="input-space"></span>

                  <div class="reportDates">
                    <date-range-picker
                        ref="picker"
                        :opens="opens"
                        :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd HH:mm:ss' }"
                        :minDate="minDate" :maxDate="maxDate"
                        :timePicker="timePicker"
                        :timePickerSeconds="timePickerSeconds"
                        :timePickerIncrement="timePickerIncrement"
                        :showDropdowns="showDropdowns"
                        v-model="dateRange"
                        :ranges="ranges"
                        @update="loadTransactions"
                        :disabled="isLoading"
                    >
                      <template v-slot:input="picker" style="min-width: 350px;">
                        {{ picker.startDate | formatDateRangeInput }} - {{ picker.endDate | formatDateRangeInput }}
                      </template>
                    </date-range-picker>
                  </div>

                  <span class="input-space"></span>

                  <div class="transDropdown">
                    <v-select multiple label="name" :options="options" v-model="selectedGame" @input="loadTransactions" :placeholder="placeholder"></v-select>
                  </div>

                  <span class="input-space"></span>

                  <div class="downloadBtn">
                    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Download
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a
                          class="dropdown-item"
                          href="#"
                          @click="download('csv')"
                      >As CSV</a>
                      <a
                          class="dropdown-item"
                          href="#"
                          @click="download('xlsx')"
                      >As Excel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover" aria-label="All games transaction by date range selected">
                <thead>
                <tr>
                  <th>Settlement Date</th>
                  <th>Provider</th>
                  <th>Category</th>
                  <th>Game</th>
                  <th>Player ID</th>
                  <th>Wager Type</th>                  
                  <th>Amount</th>
                  <th>Revenue</th>
                  <th>Jackpot Contribution</th>
                  <th>Jackpot Win</th>
                  <th>Transaction ID</th>
                  <th>GP ID</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="transactions.data.length > 0 && !isLoading">
                  <tr v-for="transaction in transactions.data" :key="transaction.id">
                    <td>{{ transaction.settlement_date | formatDate | isNotNull }}</td>                                        
                    <td>{{ transaction.game_studio | isNotNull }}</td>
                    <td>{{ transaction.game_type | isNotNull }}</td>
                    <td>{{ transaction.game_name | isNotNull }}</td>                    
                    <td>{{ transaction.player_id | isNotNull }}</td>
                    <td>{{ transaction.casino_event_type | isNotNull }}</td>                    
                    <td>{{ transaction.amount | isNotNull | formatAmountFilter }}</td>
                    <td>{{ transaction.revenue | isNotNull | formatAmountFilter }}</td>
                    <td>{{ transaction.jackpot_contribution | isNotNull | formatAmountFilter }}</td>
                    <td>{{ transaction.jackpot_win | isNotNull | formatAmountFilter }}</td>
                    <td>{{ transaction.transactionId | isNotNull }}</td>
                    <td>{{ transaction.gp_id }}</td>
                  </tr>
                </template>
                <template v-if="isLoading">
                  <tr>

                    <td colspan="13" style="text-align: center">
                      <spinner :spacing=spinnerSpacing />
                    </td>

                  </tr>
                </template>
                <template v-if="(transactions.data.length === 0 || !transactions.data) && !isLoading">
                  <tr>
                    <td colspan="13" style="text-align: center">
                      <span>No data found within those dates</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <pagination :data="transactions" :limit="2" @pagination-change-page="getResults"></pagination>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import moment from "moment";
import Spinner from 'vue-simple-spinner';


export default {
  components: {
    Spinner,
  },
  data () {
    let startDate = moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    let endDate = moment().subtract(1, 'd').endOf('day').format('YYYY-MM-DD HH:mm:ss');

    return {
      transactions: {
        data: {}
      },

      //date-range-picker params
      opens: 'center',
      dateRange: {
        startDate,
        endDate
      },
      minDate: moment('2000-01-01 HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      timePicker: true,
      timePickerSeconds: true,
      timePickerIncrement: 1,
      showDropdowns: true,
      ranges: this.setRanges(),
      //end date-range-picker params

      //dropdown options
      options: [],
      selectedGame: [],
      placeholder: "Filter by Game",

      //category dropdown options
      categoryOptions: [
        { categoryName: 'ALL', value: 'All' },
        { categoryName: 'BINGO', value: 'Bingo' },
        { categoryName: 'SLOTS', value: 'Slots' },
        { categoryName: 'SPORTSBOOK', value: 'Sportsbook' }
      ],

      categoryPlaceholder: "Filter by Category",
      selectedCategory: [],
      isLoading: true,
      spinnerSpacing: 20,
    }
  },
  methods: {
    async loadTransactions(){
      this.isLoading = true;
      this.$Progress.start();

      const selectedGames = this.selectedGame.map(game => game.id);
    
      await axios.get("/api/v1/transactions", {
        params: {
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
          gameId: selectedGames,
          category: this.selectedCategory !== null ? this.selectedCategory.categoryName : 'ALL',
        }
      }).then(({ data }) => {
        this.transactions = data;
        this.isLoading = false;
        this.$Progress.finish();
      }).catch(error => console.error(error));
    },
    async getResults(page = 1) {
      this.isLoading = true;
      this.$Progress.start();

      const selectedGames = this.selectedGame.map(game => game.id);

      await axios.get("/api/v1/transactions", {
        params: {
          page: page,
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
          gameId: selectedGames,
          category: this.selectedCategory !== null ? this.selectedCategory.categoryName : 'ALL'
        }
      }).then(({data}) => {
        this.transactions = data;
        this.isLoading = false;
        this.$Progress.finish();
      }).catch(error => console.error(error));
    },
    async getGames() {
      await axios.get("/api/v1/games")
        .then(({ data }) => {
          this.options = data.data;
        }).catch(error => console.error(error));
    },
    async download(type) {
      this.$Progress.start();
      const selectedGames = this.selectedGame.map(game => game.id);
      const category = this.selectedCategory !== null ? this.selectedCategory.categoryName : 'ALL';

      flash('Downloading now... Please wait...', 'alert alert-success');

      let data = await axios.get('/api/v1/export/gen')
      let key = data.data
      let mainPath = `${window.location.origin}/api/v1/export/transactions?startDate=${moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss')}
      &endDate=${moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')}&type=${type}&selectedGames=${selectedGames}&category=${category}`
      window.open(
        mainPath + '&encryptionKey=' + key,
        "_blank"
      )
    },
    setRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999);

      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0);

      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(23, 59, 59, 999);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);

      return {
        'Today': [today, todayEnd],
        'Yesterday': [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
        ],
      }
    }

  },
  created() {
    this.loadTransactions();
    this.getGames();
  },
  filters: {
    isNotNull(value) {
      return (value !== null) ? value : '';
    },
    formatDateRangeInput(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    formatAmountFilter(value) {
      if (value === '' || value === null) {
        value = 0.00
      }
      let val = Number(value).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}

</script>

<style>
  .input-space {
    padding-left: 35px;
  }

  .transDropdown {
    width: 300px;
  }

  .card-tools {
    text-align: center; 
    width: 100%;
  }

  .typeDropdown {
    width: 150px;
  }

  .custom-download-container {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    height: 100%;
    z-index:9999;
    text-align: center;

  }

  .midcenter {
     top: 50%;
      left: 50%;
      width: 30em;
      height: 18em;
      margin-top: -9em;
      margin-left: -15em;
      position: fixed;
  }
</style>
